import React from "react";
import Navbar from "../components/Navbar";
import DashboardSidebar from "../components/DashboardSidebar";
import profilepic from "../assets/profilepic.webp";

const Layout = ({ children }) => {
    const firstName = localStorage.getItem("firstName")
  return (
    <div className="flex flex-col min-h-screen bg-beige overflow-hidden">
      <Navbar profilePic={profilepic} />
      <div className="flex mx-3 xl:mx-6 gap-3 mb-6">
        <div className="min-h-screen w-[30%] lg:w-[22%] lg:min-w-[22%]">
          <DashboardSidebar firstname={firstName} />
        </div>
        <div className="w-full">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;

import React, { useState } from "react";
import GoogleAuthButton from "../components/GoogleAuthButton";
import { Link, useNavigate } from "react-router-dom";
import SidePannel from "../components/SidePannel";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ErrorComponent from "../components/ErrorComponent";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    fetch(`${process.env.REACT_APP_AUTH_URL}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        // response.headers.forEach((value, key) => {
        //   console.log(`${key}: ${value}`);
        // });

        return response.json().then((data) => {
          if (response.ok) {
            console.log("Login successful:", data.user);
            const authToken =
              data.user.st_access_token || localStorage.getItem("authToken");
            const isAuth = data.user.isAuth;
            const refreshToken = data.user.st_refresh_token;
            //console.log(data.user.liveChatOrganizationIdHash)
            if (authToken) {
              const currentTime = Date.now();
              localStorage.setItem("authToken", authToken);
              localStorage.setItem("refreshToken", refreshToken);
              localStorage.setItem("generatedTime", currentTime);
              localStorage.setItem("authType", "login");
              localStorage.setItem("isAuth", isAuth);
              localStorage.setItem(
                "liveChatClientIdHash",
                data.user.liveChatClientIdHash
              );
              localStorage.setItem(
                "liveChatOrganizationIdHash",
                data.user.liveChatOrganizationIdHash
              );
              if (isAuth === false) {
                navigate("/onboard");
              } else {
                navigate("/");
              }
            }

            //localStorage.setItem("googleAccessToken", data.accessToken);
          } else {
            const errorMessage = data.message || "Login failed. Please try again.";
            setError(errorMessage);
          }
        });
      })
      .catch((err) => {
        setError("An error occurred during login");
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen flex bg-beige">
      <SidePannel />
      <div className="flex flex-1 justify-center items-center p-2 xl:p-10">
        <div className="w-full">
          <div className="text-center">
            <h2 className="text-3xl font-medium mb-2">Welcome Back!</h2>
            <p className="text-darkgrey">
              Please login to access your account.{" "}
            </p>
          </div>

          <div className="flex justify-center">
            <GoogleAuthButton />
          </div>
          <div className="flex justify-center text-lightgrey font-medium">
            <span>OR</span>
          </div>
          <form
            onSubmit={handleLogin}
            className="p-2 xl:p-6 rounded w-full flex flex-col mt-8"
          >
            <div className="mb-4 mx-2 xl:mx-12">
              <label className="block mb-2" htmlFor="email">
                Email <span className="text-red">*</span>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ex.email@domain.com"
                className="w-full p-3 shadow-sm rounded-md bg-white outline-none"
                required
              />
            </div>
            <div className="mb-6  mx-2 xl:mx-12 relative">
              <label className="block mb-2" htmlFor="password">
                Password <span className="text-red">*</span>
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 shadow-sm rounded-md bg-white outline-none "
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute bottom-3 right-3 flex items-center px-2"
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </button>
            </div>
            <button
              type="submit"
              className="mx-12 bg-blue text-white p-2 rounded-full"
            >
              Login
            </button>
          </form>

          <div className="flex justify-center mt-2">
            <span className="text-gray-500">
              Don’t have an account yet?
              <Link to="/signin" className="font-medium text-blue ml-1">
                Sign up
              </Link>
            </span>
          </div>
          <div className="flex justify-center mt-2">
            <span className="text-gray-500">
              Forgot Password?
              <Link to="/forgotpassword" className="font-medium text-blue ml-1">
                Reset Now
              </Link>
            </span>
          </div>
          <div className="flex justify-centter items-center">
          {error && <ErrorComponent error={error} />}
          </div>
        
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";
import SidePannel from "../components/SidePannel";

const NewPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const resetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      let response = await submitNewPassword({
        formFields: [
          {
            id: "password",
            value: password,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "password") {
            window.alert(formField.error);
          }
        });
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        window.alert("Password reset failed. Please try again");
        window.location.assign("/resetpassword");
      } else {
        window.alert("Password reset successful!");
        localStorage.removeItem("authToken");
        window.location.assign("/login");
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  };

  return (
    <div className="min-h-screen flex bg-beige">
      <SidePannel />
      <div className="flex flex-1 justify-center items-center p-10">
        <div className="w-full">
          <div className="text-center">
            <h2 className="text-3xl font-medium mb-2">Reset Password</h2>
            <p className="text-darkgrey">
              Please enter your new password.
            </p>
          </div>

          <form
            onSubmit={resetPassword}
            className="p-6 rounded w-full flex flex-col mt-8"
          >
            {error && <div className="text-red-500 mb-4">{error}</div>}
            {message && <div className="text-green-500 mb-4">{message}</div>}
            <div className="mb-4 mx-12">
              <label className="block mb-2" htmlFor="password">
                New Password  <span className="text-red">*</span>
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 shadow-sm rounded-md bg-white outline-none"
                required
              />
            </div>
            <div className="mb-4 mx-12">
              <label className="block mb-2" htmlFor="confirmPassword">
                Confirm Password  <span className="text-red">*</span>
              </label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-3 shadow-sm rounded-md bg-white outline-none"
                required
              />
            </div>
            <button
              type="submit"
              className="mx-12 bg-blue text-white p-2 rounded-full"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;

import React from "react";
import logo from "../assets/logo.png";
import eclipses from "../assets/eclipses.png";
import smalleclipse from "../assets/small eclipses.png";
import Carousal from "./Carousal";

const SidePannel = () => {
  return (
    <div className="relative bg-blue max-w-[420px] xl:max-w-[572px] xxl:max-w-[880px]">
      <div className="absolute top-0 right-0">
        <img src={smalleclipse} alt="deisgn" />
      </div>
      <div className="absolute bottom-0 left-0 w-[60%]">
        <img src={eclipses} alt="deisgn" />
      </div>
      <div className="m-8 p-2  w-[150px]  bg-white rounded-full">
        <img src={logo} alt="logo" />
      </div>
      <div className="px-8 text-white my-3">
        <h3 className="font-semibold text-4xl leading-[58px] ">
          Welcome to Chrome <br /> Extension App
        </h3>
        <p className="text-base leading-[22px] mt-6 w-[90%]">
          We understand the value of client feedback in shaping businesses for
          success.
        </p>
      </div>
      <div className="mt-12">
        <Carousal />
      </div>
    </div>
  );
};

export default SidePannel;

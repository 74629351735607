import { signInAndUp } from "supertokens-web-js/recipe/thirdparty";

const GoogleCallback = () => {

    async function handleGoogleCallback() {
      console.log("inside handel callback");
      try {
        const response = await signInAndUp();
        if (response.status === "OK") {
          if (
            response.createdNewRecipeUser &&
            response.user.loginMethods.length === 1
          ) {
            fetch(`${process.env.REACT_APP_AUTH_URL}/signinup`, {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ method: "signUp", user: response.user }),
            })
              .then((resp) => {
                console.log("hello");
                resp.json().then((data) => {
                  console.log("inside signup", data);
                  window.location.assign(
                    `${process.env.REACT_APP_BASE_URL}/onboard`
                  );
                  localStorage.setItem("authToken", data.st_access_token);
                  localStorage.setItem("authType", "signup");
                  localStorage.setItem(
                    "liveChatClientIdHash",
                    data.liveChatClientIdHash
                  );
                  localStorage.setItem(
                    "liveChatOrganizationIdHash",
                    data.liveChatOrganizationIdHash
                  );
                });
              })
              .catch((err) => {
                console.log("error inside sign up = ");
              }); 
            console.log("successfull sign up")

          } else {  
            fetch(`${process.env.REACT_APP_AUTH_URL}/signinup`, {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ method: "signIn", user: response.user }),
            })
              .then((resp) => {
                console.log("hello2");
                resp.json().then((data) => {
                  console.log("inside signin", data);
                  window.location.assign(process.env.REACT_APP_BASE_URL);
                  localStorage.setItem("authToken", data.st_access_token);
                  localStorage.setItem("authType", "login");
                  localStorage.setItem(
                    "liveChatClientIdHash",
                    data.liveChatClientIdHash
                  );
                  localStorage.setItem(
                    "liveChatOrganizationIdHash",
                    data.liveChatOrganizationIdHash
                  );
                });
              })
              .catch((err) => {
                console.log("error inside sign in");
              }); 
            console.log("successfull sign in")
          }
        } else if (response.status === "SIGN_IN_UP_NOT_ALLOWED") {
          window.alert(response.reason);
        } else {
          window.alert(
            "No email provided by social login. Please use another form of login."
          );
        }
      } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
          window.alert(err.message);
        } else {
          console.log("errorrrrrrrr = ", err);
        }
      }
    }

    handleGoogleCallback();

  return null;
};

export default GoogleCallback;

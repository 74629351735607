import React from 'react'

const CarousalCard = ({item}) => {
  return (
    <div className="p-4 h-[280px]">
    <div className="bg-white shadow-sm rounded-lg p-2 xl:p-6 h-full flex flex-col justify-between">
      <div className="flex items-center mb-4">
        <img src={item.src} alt="Profile" className="w-12 h-12 rounded-full mr-4" />
        <div>
          <h3 className="text-sm xl:text-md font-medium">{item.name}</h3>
          <p className='text-xs text-grey'>{item.company}</p>
        </div>
      </div>
      <p className='text-xs xl:text-sm'>{item.desp}</p>
      <div className="flex flex-col xl:flex-row justify-between items-center mt-4 text-xs">
        <span className='text-grey'>{item.date}</span>
        <div className="flex">
          <span className="text-teal">★★★★★</span>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CarousalCard
import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="p-12 text-darkgrey">
      <h1 className="text-3xl font-bold mb-3 text-center">Privacy Policies</h1>
      <p className="mb-6 text-center">
        TechStack.management collects some Personal Data from its Users.
      </p>
      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold">
            Personal Data processed for the following purposes and using the
            following services:
          </h2>
          <div className="mt-4 space-y-4 pl-8">
            <div>
              <h3 className="text-md font-semibold">Advertising</h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Meta ads conversion tracking (Meta pixel)</strong>:
                  Personal Data: Trackers; Usage Data
                </li>
                <li>
                  <strong>Facebook Lookalike Audience</strong>: Personal Data:
                  Tracker; Usage Data
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">Analytics</h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Google Analytics</strong>: Personal Data: Tracker;
                  Usage Data
                </li>
                <li>
                  <strong>Google Analytics 4</strong>: Personal Data: browser
                  information; device information
                </li>
                <li>
                  <strong>
                    Google Analytics (Universal Analytics) with anonymized IP
                  </strong>
                  : Personal Data: Trackers; Usage Data
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">Contacting the User</h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Contact form</strong>: Personal Data: company name;
                  email address; first name; last name
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Displaying content from external platforms
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Video Vimeo and Google Fonts</strong>: Personal Data:
                  Tracker; Usage Data
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Handling activities related to productivity
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Apollo</strong>: Personal Data: company name; country;
                  email address; first name; last name
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Interaction with data collection platforms and other third
                parties
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>ActiveCampaign widget</strong>: Personal Data: company
                  name; email address; first name; last name; profession
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Interaction with live chat platforms
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>CRM</strong>: Personal Data: contents of the email or
                  message; email address; first name; last name; state
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Managing contacts and sending messages
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Campaigns</strong>: Personal Data: email address
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Managing support and contact requests
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>CRM Email</strong>: Personal Data: company name;
                  country; email address; first name; last name
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Platform services and hosting
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Webflow</strong>: Personal Data: various types of Data
                  as specified in the privacy policy of the service
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Remarketing and behavioral targeting
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Facebook Remarketing</strong>: Personal Data: Tracker;
                  Usage Data
                </li>
                <li>
                  <strong>Facebook Custom Audience</strong>: Personal Data:
                  email address; Tracker
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Spam and bots protection
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Cloudflare Bot Management</strong>: Personal Data: app
                  information; Application opens; browser information; browsing
                  history; city; clicks; country; county; custom events; device
                  information; device logs; geography/region; interaction
                  events; IP address; keypress events; language; latitude (of
                  city); launches; longitude (of city); metro area; motion
                  sensor events; mouse movements; number of sessions; operating
                  systems; page events; page views; province; scroll position;
                  scroll-to-page interactions; search history; session duration;
                  session statistics; state; touch events; Trackers; Usage Data;
                  video views; ZIP/Postal code
                </li>
                <li>
                  <strong>Google reCAPTCHA</strong>: Personal Data: answers to
                  questions; clicks; keypress events; motion sensor events;
                  mouse movements; scroll position; touch events; Trackers;
                  Usage Data
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">Tag Management</h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Google Tag Manager</strong>: Personal Data: Tracker
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                Traffic optimization and distribution
              </h3>
              <ul className="list-disc ml-6 text-sm">
                <li>
                  <strong>Cloudflare</strong>: Personal Data: Tracker
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-md font-semibold">
                User database management
              </h3>
              <ul className="list-disc ml-6">
                <li>
                  <strong>CRM</strong>: Personal Data: company name; email
                  address; first name; last name
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section>
          <h2 className="text-xl font-semibold">
            Information on opting out of interest-based advertising
          </h2>
          <p>
            In addition to any opt-out feature provided by any of the services
            listed in this document, Users may learn more on how to generally
            opt out of interest-based advertising within the dedicated section
            of the Cookie Policy.
          </p>
        </section>
        <section>
          <h2 className="text-xl font-semibold mb-3">Contact information</h2>
          <div className="space-y-1 pl-8">
            <p>
              <strong>Owner and Data Controller: </strong>TechStack Inc.
            </p>

            <p>
              <strong>Owner contact email: </strong>:{" "}
              <a
                href="mailto:privacy@TechStack.management"
                className="text-blue-600"
              >
                privacy@TechStack.management
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

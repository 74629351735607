import React, { useState } from "react";
import { BiSolidPencil } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { LuDownload } from "react-icons/lu";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UpdateProfile = ({ isLoading, isEditing, setIsEditing, formData }) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      navigate("/signin");
      setError("You must be logged in to update your profile");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_URL}/editprofile`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Profile update successful:", data);
        window.location.reload();
      } else {
        const data = await response.json();
        setError(data.message || "Profile update failed");
      }
    } catch (err) {
      setError("An error occurred while updating profile");
    }
  };
  return (
    <div>
      {isEditing ? (
        <button
          className="flex items-center gap-2 border border-blue shadow-lg font-medium text-blue py-2 px-4 rounded hover:bg-blue hover:text-white transition duration-200 my-4"
          onClick={handleSubmit}
        >
          <LuDownload className="rotate-180" />
          Update
        </button>
      ) : (
        <>
          {isLoading ? (
            <Skeleton width={180} />
          ) : (
            <button
              className="flex items-center gap-2 border border-blue shadow-lg font-medium text-blue py-2 px-4 rounded hover:bg-blue hover:text-white transition duration-200 my-4"
              onClick={handleEditClick}
            >
              <BiSolidPencil />
              Edit
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default UpdateProfile;

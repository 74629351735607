import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";
import SidePannel from "../components/SidePannel";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const authToken = localStorage.getItem("authToken");

  async function sendEmailClicked(email) {
    try {
      let response = await sendPasswordResetEmail({
        formFields: [
          {
            id: "email",
            value: email,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validaiton
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            window.alert(formField.error);
          }
        });
      } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
        // this can happen due to automatic account linking. Please read our account linking docs
      } else {
        // reset password email sent.
        window.alert(
          `Please check your email inbox/spam for the password reset link`
        );
      }
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH_URL}/resetpassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          credentials: "include",
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to fetch reset link");
      }

      const data = await response.json();
      sendEmailClicked(email);
      console.log(data.link);
    } catch (err) {
      if (err.isSuperTokensGeneralError === true) {
        setError(err.message);
      } else {
        setError("Looks like you gave wrong email!");
      }
      setMessage("");
    }
  };

  return (
    <div className="min-h-screen flex bg-beige">
      <SidePannel />
      <div className="flex flex-1 justify-center items-center p-3 xl:p-10">
        <div className="w-full">
          <div className="text-center">
            <h2 className="text-3xl font-medium mb-2">Reset Password</h2>
            <p className="text-darkgrey">
              Please enter email ID to access your account.
            </p>
          </div>

          <form
            onSubmit={handleResetPassword}
            className="p-3 xl:p-6 rounded w-full flex flex-col mt-8"
          >
            {error && <div className="text-red mb-4 text-center">{error}</div>}
            {message && <div className="text-green-500 mb-4">{message}</div>}
            <div className="mb-4 mx-3 lg:mx-16">
              <label className="block mb-2" htmlFor="email">
                Enter your e-mail<span className="text-red">*</span>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ex.email@domain.com"
                className="w-full p-3 shadow-sm rounded-md outline-none "
                required
              />
            </div>
            <button
              type="submit"
              className="mx-3 lg:mx-16 my-3 bg-blue text-white p-2 rounded-full"
            >
              Send link to change password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

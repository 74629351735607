import React, { useEffect, useState } from "react";
import GoogleAuthButton from "../components/GoogleAuthButton";
import { Link, useNavigate } from "react-router-dom";
import { sendVerificationEmail } from "supertokens-web-js/recipe/emailverification";
import Session from "supertokens-web-js/recipe/session";
import SidePannel from "../components/SidePannel";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { FaCircleCheck } from "react-icons/fa6";
import ErrorComponent from "../components/ErrorComponent";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [criteria, setCriteria] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
    minLength: false,
  });

  const passwordCriteria = [
    { key: "lowercase", text: "One lowercase character" },
    { key: "uppercase", text: "One uppercase character" },
    { key: "minLength", text: "8 characters minimum" },
    { key: "number", text: "One number" },
    { key: "specialChar", text: "One special character" },

  ];
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();

  //   async function sendEmail() {
  //     try {
  //         let response = await sendVerificationEmail();
  //         if (response.status === "EMAIL_ALREADY_VERIFIED_ERROR") {
  //             // This can happen if the info about email verification in the session was outdated.
  //             // Redirect the user to the home page
  //             window.location.assign("/");
  //         } else {
  //             // email was sent successfully.
  //             window.alert("Please check your email and click the link in it")
  //         }
  //     } catch (err) {
  //         if (err.isSuperTokensGeneralError === true) {
  //             // this may be a custom error message sent from the API by you.
  //             window.alert(err.message);
  //         } else {
  //             window.alert("Oops! Something went wrong.");
  //         }
  //     }
  // }

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      console.log(data.user);

      if (response.ok) {
        console.log("Signup successful:", data.user);
        const authToken =
          data.user.st_access_token || localStorage.getItem("authToken");
        const refreshToken = data.user.st_refresh_token;
        const isAuth = data.user.isAuth;

        if (authToken || refreshToken) {
          const currentTime = Date.now();
          localStorage.setItem("authToken", authToken);
          localStorage.setItem("generatedTime", currentTime);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("authType", "signin");
          localStorage.setItem("isAuth", isAuth);
          localStorage.setItem(
            "liveChatClientIdHash",
            data.user.liveChatClientIdHash
          );
          localStorage.setItem(
            "liveChatOrganizationIdHash",
            data.user.liveChatOrganizationIdHash
          );
          //await sendEmail();
          setMessage(
            "Signup successful. A verification email has been sent. Please check your email."
          );
          navigate("/onboard");
          // setTimeout(() => navigate("/verify-email"), 3000);
        }
      } else {
        setError(data.message || "Signup failed");
      }
    } catch (err) {
      setError("An error occurred during signup");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    const criteria = {
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 8,
    };
    setCriteria(criteria);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  useEffect(() => {
    const isPasswordValid =
      criteria.lowercase &&
      criteria.uppercase &&
      criteria.number &&
      criteria.specialChar &&
      criteria.minLength;

    const isEmailValid = email.includes("@") && email.includes(".");
    setIsFormValid(isEmailValid && isPasswordValid);
  }, [email, criteria]);
  return (
    <div className="min-h-screen flex bg-beige">
      <SidePannel />
      <div className="flex flex-1 justify-center items-center p-3 xl:p-10">
        <div className="w-full">
          <div className="text-center">
            <h2 className="text-3xl font-semibold mb-4">
              Sign up with free trial
            </h2>
            <p className="text-base text-darkgrey my-4">
              Empower your experience, sign up for a free account today
            </p>
          </div>

          <div className="flex justify-center">
            <GoogleAuthButton />
          </div>
          <div className="flex justify-center text-lightgrey font-medium">
            <span>OR</span>
          </div>

          <form
            onSubmit={handleSignUp}
            className="p-3 xl:p-6 rounded w-full flex flex-col mt-3"
          >
            {message && <div className="text-green-500 mb-4">{message}</div>}
            <div className="mb-3 mx-2 xl:mx-12">
              <label className="block mb-2" htmlFor="email">
                Email <span className="text-red">*</span>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="ex.email@domain.com"
                className="w-full p-3 shadow-sm rounded-md bg-white outline-none"
                required
              />
            </div>
            <div className="mb-3  mx-2 xl:mx-12 relative">
              <label className="block mb-2" htmlFor="password">
                Password <span className="text-red">*</span>
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                placeholder="Enter password"
                onChange={handlePasswordChange}
                className="w-full p-3 shadow-sm rounded-md bg-white outline-none "
                required
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute bottom-3 right-3 flex items-center px-2"
              >
                {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
              </button>
            </div>
            {/* password validation*/}
            <div className="mx-2 xl:mx-12 my-2 text-lightgrey">
            <ul className="grid grid-cols-1 lg:grid-cols-2 gap-2 leading-8">
                {passwordCriteria.map((criterion, index) => (
                  <li
                    key={criterion.key}
                    className={`flex items-center gap-2 ${
                      criteria[criterion.key] ? "text-blue" : "text-lightgrey"
                    } ${index < 0 ? "lg:col-span-2" : ""}`}
                  >
                    <FaCircleCheck />
                    {criterion.text}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mx-2 xl:mx-12 my-4 text-sm text-darkgrey">
              <span>
                By registering for an account, you are consenting to our{" "}
                <Link to="/terms_and_conditions" className="text-blue underline">
                  Terms of Service
                </Link>{" "}
                and confirming that you have reviewed and accepted the{" "}
                <Link to="/privacy_policies" className="text-blue">
                  Global Privacy Statement.
                </Link>
              </span>
            </div>
             <button
              type="submit"
              className={`mx-2 xl:mx-12 ${isFormValid ? "bg-blue cursor-pointer" : "bg-grey cursor-not-allowed"}  text-white p-2 rounded-full`}
              disabled={!isFormValid}
            >
              Get started free
            </button>
          </form>

          <div className="flex justify-center mt-2">
            <span className="text-gray-500">
              Already have an account?
              <Link to="/login" className="font-medium text-blue ml-1">
                Login
              </Link>
            </span>
          </div>
          <div className="flex justify-centter items-center">
          {error && <ErrorComponent error={error} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

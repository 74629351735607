import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarousalCard from "./CarousalCard";
import human1 from "../assets/casousal/human1.png";
import human2 from "../assets/casousal/human2.png";
import human3 from "../assets/casousal/human3.png";
import human4 from "../assets/casousal/human4.png";

const Carousal = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    centerMode: true,
    centerPadding: "60px",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setActiveSlide(next),
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "-30px",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <ul
          style={{
            margin: "0px",
            display: "flex",
            width: "0",
            justifyContent: "center",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          background: activeSlide === i ? "white" : "#d1d5db",
        }}
      ></div>
    ),
  };

  const carousalData = [
    {
      src: human1,
      name: "Adam Cai",
      company: "Tutti AI - Tax | CEO & Co-Founder",
      desp: "Highly recommend using TechStack for quality leads via LinkedIn Sales Navigator. Achieved 20-25% customer response rate on first attempt. Give them a shot if you're looking to onboard more customers.",
      date: "3:15 PM - Nov 15, 2022",
    },

    {
      src: human2,
      name: "Patrick",
      company: "STP Ventures | CTO & CISO",
      desp: "We are definitely saving on our budget right now AND getting better results than the outbound vendors we had hired to do this.",
      date: "6:55 PM - Jul 15, 2022",
    },
    {
      src: human3,
      name: "Sindhu",
      company: "We Crush Events | CEO & Founder",
      desp: "They helped We Crush generate incremental business with their advice.",
      date: "9:03 AM - May 7, 2022",
    },
    {
      src: human4,
      name: "Sheryl",
      company: "STP Ventures | CEO",
      desp: "We have immediately gotten leads, and it has freed up large amounts of time.",
      date: "8:35 PM - JAN 4, 2022",
    },
  ];

  return (
    <div className="bg-blue max-w-[550px] mx-auto p-6 relative">
      <Slider {...settings}>
        {carousalData.map((item, index) => (
          <div
            key={index}
            className={`transition-transform duration-300 ${
              activeSlide === index ? "scale-110" : ""
            }`}
          >
            <CarousalCard item={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", borderRadius: "50%" }}
      onClick={onClick}
    />
  );
};

export default Carousal;

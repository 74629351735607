import React, { useEffect, useState } from "react";
import profilepic from "../../assets/profilepic.webp";
import { Navigate, useNavigate } from "react-router-dom";
import { refreshAuthToken } from "../../utils/refreshToken";
import ProfileCard from "../../components/ProfileCard";
import Layout from "../../utils/Layout";
import DialogBox from "../../components/DialogBoz";

const Dashboard = () => {
  const [profile, setProfile] = useState({});
  const isAuth = localStorage.getItem("isAuth");
  const [error, setError] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const fetchProfile = async () => {
    const authToken = localStorage.getItem("authToken");

    if (isAuth === false) {
      return <Navigate to="/onboard" />;
    }

    if (authToken) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_AUTH_URL}/getprofile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setProfile(data.user);
          localStorage.setItem("firstName", data.user.firstName);
        } else if (response.status === 401) {
          const refreshSuccessful = await refreshAuthToken();
          if (refreshSuccessful) await fetchProfile();
        } else {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch profile data");
        }
      } catch (err) {
        console.error("Fetch error:", err);
        setIsDialogOpen(true);
        setError(err);
        localStorage.removeItem("authToken");
        localStorage.removeItem("refreshToken");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const checkProfileLoad = () => {
    setTimeout(() => {
      if (!profile.firstName && !isLoading) {
        window.location.reload();
      }
    }, 2000);
  };

  useEffect(() => {
    fetchProfile().then(() => {
      checkProfileLoad();
    });
  }, []);

  const closeDialog = () => {
    setIsDialogOpen(false);
    navigate("/login");
  };

  return (
    <Layout profile={profile}>
      <DialogBox
        isOpen={isDialogOpen}
        onClose={closeDialog}
        title="Session Expired"
        message="Your session has expired. Please log in again."
      />
        <ProfileCard
        isLoading={isLoading}
          profilePic={profilepic}
          firstname={profile.firstName}
          lastName={profile.lastName}
          email={profile.email}
          phone={profile.phone}
        />
    </Layout>
  );
};

export default Dashboard;
